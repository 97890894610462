@layer components {
  /* Button base styles */
  .badge {
    @apply text-xs font-medium me-2 px-2.5 py-0.5 rounded;

    &.badge-with-dismiss {
      @apply inline-flex items-center;
    }

    & .badge-dismiss-button {
      @apply inline-flex items-center ms-2 bg-transparent rounded-sm;
    }

    /* Theme-specific styles for badge */
    &.badge-primary {
      @apply bg-primary-100 text-primary-800;
      &.badge-bordered {
        @apply border border-primary-400;
      }
      & .badge-dismiss-button {
        @apply text-primary-400 hover:text-primary-900;
      }
    }

    &.badge-secondary {
      @apply bg-secondary-100 text-secondary-800;
      &.badge-bordered {
        @apply border border-secondary-400;
      }
      & .badge-dismiss-button {
        @apply text-secondary-400 hover:text-secondary-900;
      }
    }

    &.badge-accent {
      @apply bg-accent-100 text-accent-800;
      &.badge-bordered {
        @apply border border-accent-400;
      }
      & .badge-dismiss-button {
        @apply text-accent-400 hover:text-accent-900;
      }
    }

    &.badge-danger {
      @apply bg-red-100 text-red-800;
      &.badge-bordered {
        @apply border border-red-400;
      }
      & .badge-dismiss-button {
        @apply text-red-400 hover:text-red-900;
      }
    }

    &.badge-light {
      @apply bg-gray-100 text-gray-800;
      &.badge-bordered {
        @apply border border-gray-400;
      }
      & .badge-dismiss-button {
        @apply text-gray-400 hover:text-gray-900;
      }
    }

    &.badge-input {
      @apply bg-white text-primary-800;
      &.badge-bordered {
        @apply border border-primary-400;
      }
      & .badge-dismiss-button {
        @apply text-primary-400 hover:text-primary-900;
      }
    }

    /* Fully rounded edges */
    &.badge-pills {
      @apply rounded-full;
    }

    /* Size variants */
    &.badge-sm {
      @apply text-xs px-2.5 py-0.5;
      & i {
        @apply text-xs;
      }
    }

    &.badge-md {
      @apply text-sm px-3 py-1;
      & i {
        @apply text-sm;
      }
    }

    &.badge-lg {
      @apply text-base px-4 py-2;
      & i {
        @apply text-base;
      }
    }
  }
}
