@layer components {
  /* Button base styles */
  .btn {
    @apply inline-flex items-center justify-center rounded-md font-medium text-base focus:outline-none focus:ring-1 focus:ring-offset-1 disabled:opacity-25 transition ease-in-out duration-150;

    /* Theme-specific styles for standard and icon buttons */
    &.btn-primary {
      @apply bg-primary-500 text-white hover:bg-primary-600 focus:ring-primary-300;
    }

    &.btn-secondary {
      @apply bg-secondary-500 text-white hover:bg-secondary-600 focus:ring-secondary-300;
    }

    &.btn-accent {
      @apply bg-accent-500 text-white hover:bg-accent-600 focus:ring-accent-300;
    }

    &.btn-danger {
      @apply bg-red-500 text-white hover:bg-red-600 focus:ring-red-300;
    }

    &.btn-light {
      @apply bg-white text-gray-800 hover:bg-gray-300 focus:ring-gray-300;
    }

    &.btn-input {
      @apply bg-white text-gray-900 border-gray-300 hover:bg-gray-50;
    }

    /* Outline variant */
    &.btn-outline {
      @apply border text-current;

      /* Padding adjustment strategy */
      &.btn-size-xs {
        @apply px-[calc(theme(spacing.3)-2px)] py-[calc(theme(spacing.2)-2px)];
      }

      &.btn-size-sm {
        @apply px-[calc(theme(spacing.3)-2px)] py-[calc(theme(spacing.2)-2px)];
      }

      &.btn-size-base {
        @apply px-[calc(theme(spacing.5)-2px)] py-[calc(theme(spacing[2.5])-2px)];
      }

      &.btn-size-lg {
        @apply px-[calc(theme(spacing.5)-2px)] py-[calc(theme(spacing.3)-2px)];
      }

      &.btn-size-xl {
        @apply px-[calc(theme(spacing.6)-2px)] py-[calc(theme(spacing[3.5])-2px)];
      }

      &:hover {
        @apply bg-gray-100;
      }

      &:focus {
        @apply ring-2 ring-offset-2;
      }
    }

    &.btn-outline.btn-primary {
      @apply border-primary-500 text-primary-500;
      &:hover {
        @apply bg-primary-100;
      }
      &:focus {
        @apply ring-primary-300;
      }
    }

    &.btn-outline.btn-secondary {
      @apply border-secondary-500 text-secondary-500;
      &:hover {
        @apply bg-secondary-100;
      }
      &:focus {
        @apply ring-secondary-300;
      }
    }

    &.btn-outline.btn-accent {
      @apply border-accent-500 text-accent-500;
      &:hover {
        @apply bg-accent-100;
      }
      &:focus {
        @apply ring-accent-300;
      }
    }

    &.btn-outline.btn-danger {
      @apply border-red-500 text-red-500;
      &:hover {
        @apply bg-red-100;
      }
      &:focus {
        @apply ring-red-300;
      }
    }

    &.btn-outline.btn-light {
      @apply border-gray-500 text-gray-500;
      &:hover {
        @apply bg-gray-100;
      }
      &:focus {
        @apply ring-gray-300;
      }
    }

    &.btn-outline.btn-input {
      @apply border-gray-300 text-gray-900;
      &:hover {
        @apply bg-gray-50;
      }
      &:focus {
        @apply ring-gray-300;
      }
    }

    /* Fully rounded edges */
    &.btn-pills {
      @apply rounded-full;
    }

    /* Size variants */
    &.btn-size-xs {
      @apply px-3 py-2 text-xs;
      & > * {
        @apply text-xs;
      }
    }

    &.btn-size-sm {
      @apply px-3 py-2 text-sm;
      & > * {
        @apply text-sm;
      }
    }

    &.btn-size-base {
      @apply px-5 py-2.5 text-sm;
      & > * {
        @apply text-sm;
      }
    }

    &.btn-size-lg {
      @apply px-5 py-3 text-base;
      & > * {
        @apply text-base;
      }
    }

    &.btn-size-xl {
      @apply px-6 py-3.5 text-base;
      & > * {
        @apply text-base;
      }
    }
  }
}
