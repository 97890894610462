@import 'assets/scss/button.scss';
@import 'assets/scss/badge.scss';

@layer components {
  /* Input base styles */
  .app-input {
    @apply block w-full rounded-md border-primary-300 shadow-sm  sm:text-sm placeholder-gray-400 text-gray-900;

    &.primary {
      @apply border-primary-300 text-primary-900 focus:ring-primary-500 focus:border-primary-500;
    }

    &.secondary {
      @apply border-secondary-300 text-secondary-900 focus:ring-secondary-500 focus:border-secondary-500;
    }

    &:disabled,
    &.disabled {
      @apply bg-gray-200 border-gray-300 text-gray-500;

      &::placeholder {
        @apply text-gray-400 dark:text-gray-500;
      }
    }

    /* Input sizes */
    &.sm {
      @apply px-[calc(theme(spacing.3)-2px)] py-[calc(theme(spacing.2)-2px)];
    }

    &.base {
      @apply px-[calc(theme(spacing.5)-2px)] py-[calc(theme(spacing[2.5])-2px)];
    }

    &.lg {
      @apply px-[calc(theme(spacing.5)-2px)] py-[calc(theme(spacing.3)-2px)];
    }

    &.invalid-input {
      @apply border-red-500 focus:border-red-500 focus:ring-red-500;
    }
  }

  /* Link base styles */
  .app-link {
    @apply text-secondary-700  underline text-xs transition-colors duration-150;

    &:hover {
      @apply text-secondary-800;
    }
  }

  .app-badge {
    @apply text-xs font-medium px-2 py-0.5 rounded border;

    &.primary {
      @apply text-primary-800 bg-primary-100 border-primary-200;
    }

    &.secondary {
      @apply text-secondary-800 bg-secondary-100 border-secondary-200;
    }

    &.accent {
      @apply text-accent-800 bg-accent-100 border-accent-200;
    }

    &.danger {
      @apply text-red-800 bg-red-100 border-red-200;
    }

    &.light {
      @apply text-gray-800 bg-gray-100 border-gray-200;
    }
  }

  /* Table base styles */
  #app-list-page {
    &:not(.is-mobile) {
      /* @apply flex flex-col h-[calc(100vh-159px)]; */
      @apply flex flex-col gap-2;
    }

    /* &.transactions-list-page {
      @apply h-[calc(100vh-85px)];
    } */

    &.is-mobile {
      @apply flex flex-col;

      /* section.content {
        @apply h-[calc(100vh-80px)];
      } */
    }
  }

  /* #app-table__header { */
  /* @apply flex justify-between gap-4; */

  .app-table__filters {
    @apply w-full md:grid-cols-12 gap-x-4 m-auto hidden md:grid items-center mb-1;

    .filter__item {
      @apply w-full;
    }
  }
  /* } */

  .app-table__wrapper {
    @apply bg-white border border-gray-200 rounded-lg;
    max-height: 96%;
  }

  .app-table {
    @apply w-full table-auto text-sm text-left text-primary-500;

    thead,
    .thead {
      @apply sticky bg-primary-50 text-xs text-primary-700 uppercase z-50;
      top: -1px;

      tr th {
        @apply px-3 py-2;
      }
    }

    tbody,
    .table-body {
      @apply divide-y divide-gray-200;

      tr {
        @apply bg-white hover:bg-gray-50;

        &:not(:last-child) {
          @apply rounded-b;
        }

        td {
          @apply px-3 py-2;
        }
      }
    }
  }

  .app-form {
    @apply w-full flex flex-col;

    &__row {
      @apply mb-4 flex gap-4 flex-col w-full  md:flex-row;
    }

    &__row:last-child {
      @apply mb-0;
    }

    &__col {
      @apply mb-4 flex gap-4 flex-row w-full;
    }

    &__grid-row {
      @apply grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-12 mb-6;
    }
  }

  .app-card {
    @apply flex flex-col gap-x-2 gap-y-2 bg-white px-3 py-3 w-full border sm:rounded-md;

    &__title {
      @apply text-xs sm:text-sm font-medium leading-6 text-gray-500;
    }
    &__body {
      @apply h-full;
    }
  }

  .profile-form {
    &__content {
      @apply bg-white dark:bg-gray-800 px-4 py-5 sm:p-6 sm:rounded-t-md shadow sm:rounded-tl-md sm:rounded-tr-md;

      &--grid {
        @apply grid grid-cols-6 gap-6;
      }

      &--item {
        @apply col-span-6 sm:col-span-4;
      }
    }

    &__footer {
      @apply flex items-center justify-end px-4 py-3 bg-gray-50 dark:bg-gray-800 text-end sm:px-6 shadow sm:rounded-bl-md sm:rounded-br-md;
    }
  }
}
