/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
/* Custom com */
@import './custom-components.scss';
@tailwind utilities;

/* Angular cdk overlay */
@import '~@angular/cdk/overlay-prebuilt.css';

html {
  @apply h-full;
}

body {
  @apply h-full;
}

main {
  @apply h-full;
}

.app-overlay-backdrop {
  z-index: 80;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
